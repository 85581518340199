import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import '../less/about.less'
import { Link } from 'gatsby'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import MuiLink from '@mui/material/Link'
import ourIcon from '../images/icon.png'

export default function About (props) {
  return (
    <PageWrapper id="about">
      <HeadMeta {...props} />
      <Container>
        <Typography variant="h1" gutterBottom>About</Typography>
        <Typography>FastImageMin is an image optimization service that aims to provide you with truly simple, fast, and efficient image compression.</Typography>
        <Typography>We created this free service so that people can benefits the technology for their lives in a simple, unbloated, and efficient manner.</Typography>
        <Typography>Up until now, we don&apos;t collect your private data nor storing any of your images, as we believe we want this service to be just useful for the world and the community. It&apos;s simple as that.</Typography>
        <Typography>We hope you truly enjoying using our service, and please give your feedback if you have time.</Typography>
        <br />
        <Typography>
          <Link to="https://bagus.sasikirono.com" rel="nofollow" className="creator-link">Bagus Sasikirono</Link>
          <br />
          Creator of FastImageMin
        </Typography>
      </Container>
    </PageWrapper>
  )
}

function HeadMeta (props) {
  const jsonLDSchema = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebApplication',
      browserRequirements: 'Requires ECMAScript 2015',
      name: 'FastImageMin',
      description: 'FastImageMin optimizes JPEG, PNG, and WebP images efficiently with better compression rate and less overhead. You can also resize the image to the appropriate best size.',
      url: 'https://fastimagemin.com',
      image: ourIcon,
      thumbnail: ourIcon,
      applicationCategory: 'UtilitiesApplication',
      applicationSubCategory: 'Image Compression Tool',
      author: {
        '@context': 'https://schema.org',
        '@type': 'Person',
        name: 'Bagus Sasikirono',
        url: 'https://bagus.sasikirono.com'
      },
      maintainer: {
        '@context': 'https://schema.org',
        '@type': 'Person',
        name: 'Bagus Sasikirono',
        url: 'https://bagus.sasikirono.com'
      }
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [{
        '@type': 'ListItem',
        position: 1,
        name: 'About',
        item: 'https://fastimagemin.com/about'
      }]
    }
  ]
  return (
    <Helmet defer={false}>
      <title>About | FastImageMin</title>

      <script type="application/ld+json">
        {JSON.stringify(jsonLDSchema)}
      </script>

    </Helmet>
  )
}
